/* .layout */
.layout{
	height:100%;
}
.layout{
	display:table;
	width:100%;
	table-layout:fixed;
	overflow:hidden;
	background:$back-base;
}
.layout-footer{
	display:table-row;
	height:1%;
}
.layout-main{

}
/* .back_ */
.back_dark{
	background:$back-dark;
	color:$text-white;
}
.back_dark_dirty{
	background:$back-dark_dirty;
}
/* .section- */
.section-inside{
	padding-top:40px;
	padding-bottom:40px;
}
.section-heading{
	background:$back-dark_black;
	color:$text-brand;
	padding:16px 0;
	font-size:22px;
	line-height:1.1em;
	text-transform:uppercase;
	color:$text-brand;
	font-weight:700;
}
/* .wrapper */
.wrapper{
	width:96%;
	max-width:1250px;
	margin-left:auto;
	margin-right:auto;
}
.wrapper-inside{
	width:100%;
	max-width:1000px;
	margin-left:auto;
	margin-right:auto;
}
/* .logo */
.logo{
	display:block;
	width:136px;
}
.logo__output{
	display:block;
	width:100%;
	height:auto;
}
/* .cols */
.cols-clear{
	display:inline-block;
	vertical-align:top;
	width:100%;
}
.cols{
	display:flex;
	flex-flow:row wrap;
	justify-content:flex-start;

	&._items-stretch{
		align-items:stretch;
	}
	&._vertical-middle{
		align-items:center;
	}
	&._vertical-top{
		align-items:flex-start;
	}
	&._align-center{
		justify-content:center;
	}
	&._align-justify{
		justify-content:space-between;
	}
	&._align-right{
		justify-content:flex-end;
	}
	&._nowrap{
		flex-wrap:nowrap;
	}
	&._indent-large{
		margin:-20px;

		> .col-temp{
			padding:20px;
		}
	}
	&._indent-base{
		margin:-15px;

		> .col-temp{
			padding:15px;
		}
	}
	&._indent-md{
		margin:-8px;

		> .col-temp{
			padding:8px;
		}
	}
	&._indent-sm{
		margin:-5px;

		> .col-temp{
			padding:5px;
		}
	}
	// &._clean{
	// 	margin:0;

	// 	> .col-temp{
	// 		padding:0;
	// 	}
	// }
}
.col-temp{

}
//.col-16{
//	width:16.66%;
//}
// .col-16x3{
// 	width:calc(16.66% * 3);
// }
.col-20{
	width:20%;
}
.col-25{
	width:25%;
}
.col-33{
	width:33.33%;
}
.col-50{
	width:50%;
}
.col-66{
	width:66.66%;
}
.col-75{
	width:75%;
}
.col-100{
	width:100%;
}
.col-stretch{
	display:flex;
	flex-flow:row wrap;
	justify-content:flex-start;
}
.col-stretch__content{
	width:100%;
}
/* .flex-spacer */
.flex-spacer{
	display:flex;
	flex-direction:column;
}
.flex-spacer__content{
	flex:1 0 auto;
}
.flex-spacer__footer{
	flex:0 0 auto;
}
/* .divider */
.divider{
	font-size:0;
	line-height:0;
	border:none;
	border-top:2px solid currentColor;
	margin-left:auto;
	margin-right:auto;

	&._wide{
		max-width:870px;
	}
	&._short{
		max-width:40px;
	}
	&._left{
		margin-left:0;
	}
}
/* .accordion */
.accordion__drop{
	display:none;

	// &:not(._deafult-show){
	// 	display:none;
	// }
	&._active{
		display:block;
	}
}
/* .accordionbox */
.accordionbox{
	border:1px solid $text-dark;
}
.accordionbox__button{
	text-align:left;
	display:block;
	width:100%;
	text-transform:uppercase;
	color:$text-dark;
	font-size:16px;
	line-height:18px;
	font-weight:700;
	// padding:16px 30px 16px 24px;
	background:$brand;

	&._active{

		.accordionbox__button-icon{
			transform:rotateZ(-180deg);
		}
	}
}
.accordionbox__button-col{
	min-height:50px;

	&._content{
		padding:16px 24px;
		flex-grow:100;
		width:1%;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	&._side{
		width:50px;
		flex-shrink:1;
		display:flex;
		justify-content:center;
		align-items:center;
	}
}
.accordionbox__button-icon{
	width:24px;
	height:24px;
}
.accordionbox__body{
	border-top:1px solid $text-dark;
	padding:24px;
}
/* .venobox */
.js-venobox{
	cursor:pointer;
}
.venobox-cover{
	display:block;
	position:relative;
	z-index:1;
	text-decoration:none;
	background:$back-dark_black;
	color:$text-white;

	&:hover{
		text-decoration:none;
	}
}
.venobox-cover__icon{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:1;
	margin:auto;
	width:20%;
	height:auto;
}
.venobox-cover__photo{
	
}
.vbox-overlay{
	background:$overlay !important;
}
.vbox-overlay *,
.vbox-overlay *:before,
.vbox-overlay *:after{
	-webkit-backface-visibility: hidden;
}
.vbox-overlay{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1040;
	-webkit-transform:translateZ(1000px);
	transform: translateZ(1000px);
	transform-style: preserve-3d;

	&._vbox-title{

		.vbox-container img{
			padding-top:calc(60px + 20px) !important;
		}
	}
}
.vbox-title{
	width: 100%;
	height: 60px;
	float: left;
	text-align: center;
	line-height: 16px;
	font-size: 12px;
	padding: 22px 60px;
	overflow: hidden;
	position: fixed;
	display: none;
	left: 0;
	color:$text-white !important;
	background:rgba(0,0,0,0.6) !important;
	z-index: 1050;
}
.vbox-close,
.vbox-next,
.vbox-prev{
	width:60px;
	height:60px;
	padding:10px;
	display:block;
	filter:drop-shadow(0 2px 3px rgba(0,0,0,0.50));
	color:$text-white !important;
	font-size:0;
	line-height:0;
	// @include transition-base(color);

	&:hover{
		color:$brand !important;
	}
}
.vbox-close{
	cursor:pointer;
	position:fixed;
	top:0;
	right:0;
	overflow:hidden;
	z-index:1050;
	background:none !important;

	&:after{
		content:'';
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
	}
}
.vbox-num{
	cursor: pointer;
	position: fixed;
	left: 0;
	height: 40px;
	display: block;
	overflow: hidden;
	line-height: 28px;
	font-size: 12px;
	padding: 6px 10px;
	display: none;
	z-index: 1050;
}
.vbox-next,
.vbox-prev{
	position: fixed;
	top: 50%;
	margin-top: -20px;
	overflow: hidden;
	cursor: pointer;
	z-index: 1050;
}
.vbox-prev{
	left:15px;
}
.vbox-next{
	right:15px;
}
.venobox__icon{
	width:100%;
	height:100%;
}
.vbox-preloader{

	div{
		display:none;
	}
}
.venobox__loader{
	width:50px;
	height:50px;
}
.vbox-inline{
	overflow-y:auto;
	overflow-x:hidden;
	background:#fff;
	box-shadow:none;
	/*max-width:90%;*/
	max-width:950px;
	max-height:90%;
	width:100%;
	position:absolute;
	top:50%;
	transform:translateY(-50%);
	left:0;
	right:0;
	padding:20px !important;
	margin-left:auto;
	margin-right:auto;
}
.venoframe{
	//iframe, youtube
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	margin:auto;
	border:none;
	width:100%;
	height:100%;
	max-width:80%;
	max-height:80%;
	background:none !important;
}
.venoframe.vbvid{
	// height: 260px;
}
@media (min-width: 768px) {
	.venoframe,
	.vbox-inline{
		/*width: 90%;
		height: 360px;
		height: 70vh;*/
	}
	.venoframe.vbvid{
		/*width: 640px;
		height: 360px;*/
	}
}
@media screen and (max-width:600px){
	.venoframe{
		//iframe youtube
		height:300px;
	}
}
@media screen and (max-width:480px){
	.venoframe{
		//iframe youtube
		height:200px;
	}
}
@media (min-width: 992px) {
	.venoframe,
	.vbox-inline{
		/*max-width: 1200px;
		width: 80%;
		height: 540px;
		height: 70vh;*/
	}
	.venoframe.vbvid{
		/*width: 960px;
		height: 540px;*/
	}
}
.vbox-open{
	overflow: hidden;
}
.vbox-container{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow:hidden;
	overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
	z-index: 20;
	width:100%;
	height:100%;
}
.vbox-content{
	text-align: center;
	float: left;
	width: 100%;
	position: relative;
	overflow: hidden;
	height:100%;
	margin:0 !important;
}
.vbox-container img{
	max-width:100%;
	height:auto;
}
.figlio{
	text-align:left;
}
img.figlio{
	box-shadow:none;
	user-select: none;
	max-width:100%;
	height:auto;
	max-height:100%;
	width:auto;
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	padding:20px !important;
	margin:auto;
	background:none !important;
}
.vbox-content.swipe-left{
	transform:translateX(-200px);
}
.vbox-content.swipe-right{
	transform:translateX(200px);
}
.animated{
	@include transition-base(transform);
}
.animate-in{
	opacity: 1;
}
.animate-out{
	opacity: 0;
}
.venobox-inline-wrapper{
	display:none;
}
/* .header */
.header{

}
.header__wrapper{
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.header__side-logo{
	padding:10px 0;
}
.header__content{
	padding:10px 0 10px 16px;
}
/* .contact-heading */
.contact-heading{
	font-size:18px;
	line-height:20px;
	font-weight:700;
	overflow:hidden;
}
/* .nav */
.nav{

}
.nav-list{

}
.nav-list__item{

}
%nav-list__link-temp{
	text-transform:uppercase;
	display:block;
	text-decoration:none;
	color:$brand;
	line-height:14px;
	padding-left:12px;
	padding-right:12px;
	text-align:left;

	&:hover{
		text-decoration:none;
	}
	&._active-nav{
		color:$text-dirty;
	}
	@media screen and (min-width:1025px){
		&:focus,
		&:hover{
			color:$text-dirty;
		}
	}
}
.nav-list__link{
	@extend %nav-list__link-temp;
	font-weight:700;
}
.nav-list__link-icon{

}
.nav-list__dropdown{


}
.nav-list__dropdown-button{
	display:block;
	width:100%;
	position:relative;
	z-index:1;
	padding-right:16px;
}
.nav-list__dropdown-buttonicon{
	position:absolute;
	right:0;
}
.nav-list__dropdown-list{
	position:absolute;
	top:-9999px;
	left:-9999px;
}
.nav-list__dropdown-item{

}
.nav-list__dropdown-link{
	@extend %nav-list__link-temp;
	font-size:11px;
}
/* .button-burger */
.button-burger{
	width:50px;
	height:50px;
	padding:8px;
	color:$brand_b;

	&._active{

		.button-burger__divider{

			&:nth-child(1){
				transform:translateY(11px) rotate(45deg);
			}
			&:nth-child(2){
				opacity:0;
				transform:translateY(0px) rotate(0deg);
			}
			&:nth-child(3){
				transform:translateY(-9px) rotate(-45deg);
			}
		}
	}
}
.button-burger__divider{
	background:currentColor;
	// @include transition-base;
	height:4px;
	display:block;
	margin-top:6px;

	&:first-child{
		margin-top:0;
	}
}
/* .promo */
.promo{
	display:table;
	height:460px;
	width:100%;
	text-align:center;
	position:relative;
	z-index:1;
}
.promo__body{
	display:table-cell;
	vertical-align:middle;
}
.promo__photo{
	position:absolute;
	top:0;
	left:0;
	z-index:-1;
	width:100%;
	height:100%;
	object-fit:cover;
	object-position:50% 50%;
}
/* .tubes */
.tubes{

}
.tube{
	color:$text-white;
	text-align:center;

	&:nth-child(odd){

		.tube__frame{
			background:$back-dark_dirty;
		}
	}
	&:nth-child(even){

		.tube__frame{
			background:$back-dark_black;
		}
	}
}
.tube__frame{
	padding:30px 16px 40px;
}
.tube__photo{
	height:120px;
	object-fit:cover;
	object-position:50% 50%;
}
/* .slick */
.slick-slider *{
	outline:none;
}
.slick-container{

}
.slick-container__init{
	height:0;
	overflow:hidden;

	&.slick-initialized{
		height:auto;
		overflow:visible;
	}
}
.slick-track,
.slick-list{
	transform:translate3d(0, 0, 0);
}
.slick-list{
	overflow:hidden;
}
.slick-track{
	display:block;
	position:relative;
	top:0;
	left:0;
}
.slick-slide{
	float:left;
}
.slick-active{

}
.slick-prev,
.slick-next{
	display:none !important;
}
.slick-prev{

}
.slick-next{

}
.slick-disabled{

}
.slick-dots{
	text-align:center;
	font-size:0;
	line-height:0;
	@extend .line-content;

	li{
		display:inline-block;
		vertical-align:top;
		padding:5px;
	}
	.slick-active{
		
		button{
			color:$brand;
			transform:scale(1);
		}
	}
	button{
		@extend %button;
		@include transition-base(color, background-color, transform);
		text-indent:-9999px;
		overflow:hidden;
		width:14px;
		height:14px;
		border-radius:50%;
		color:$text-dirty;
		background-color:currentColor;
		transform:scale(.65);
	}
}
/* .slider-simple */
.slide-simple{
	@extend %paragraph-base;
}
/* .footer */
.footer{
	text-align:center;
}
/* .column-list */
.column-list{
	column-count:4;
	column-gap:0;
	margin:-8px;
}
.column-list__item{
	page-break-inside:avoid;
	break-inside:avoid;
	padding:8px;
}
/* .map-frame */
.map-frame{
	border:none;
	width:100%;
	height:450px;
}
/* @media */
@media screen and (max-width:1360px){
	/* .cols */
	.col-temp{

		&._media-wide_solo{
			width:100%;
		}
	}
}/* (max-width:1360px) */
@media screen and (max-width:1152px){
	/* .cols */
	.col-temp{

		&._media-regular_solo{
			width:100%;
		}
		&._media-regular_half{
			width:50%;
		}
	}
}
@media screen and (min-width:1025px){
	/* .media- */
	.media-tablet_show{
		display:none;
	}
	/* .nav */
	.nav-list{
		display:flex;
	}
	.nav-list__link{
		font-size:12px;
		padding-top:16px;
		padding-bottom:16px;
	}
	.nav-list__dropdown{
		position:relative;
		z-index:10;

		&:hover,
		&:focus-within{

			.nav-list__link{
				color:$text-dirty;
			}
			.nav-list__dropdown-list{
				top:100%;
				left:0;
			}
		}
	}
	.nav-list__dropdown-list{
		position:absolute;
		width:210px;
		background:$back-dark;
	}
	.nav-list__dropdown-link{
		padding-top:12px;
		padding-bottom:12px;
	}
	.nav-list__dropdown-buttonicon{
		top:15px;
	}
	/* .slick */
	.slick-dots{

		button{

			&:focus{
				color:$brand;
			}
			&:hover{
				color:$text-base;
			}
		}
	}
	/* .accordionbox */
	.accordionbox__button{

		&:focus,
		&:hover{
			color:$text-white;
		}
	}
	/* .venobox */
	.venobox-cover{

		&:hover{

			.venobox-cover__icon{
				display:block;
			}
			.venobox-cover__photo{
				opacity:.4;
			}
		}
	}
	.venobox-cover__icon{
		display:none;
	}
}/* min-width:1025px */
@media screen and (max-width:1024px){
	/* .line- */
	.line-section{
		margin-top:60px;
	}
	/* .cols */
	.cols{

		&._indent-large{
			margin:-15px;

			> .col-temp{
				padding:15px;
			}
		}
	}
	.col-20{

		&:not(._not-change){
			width:25%;
		}
	}
	.col-25{

		&:not(._not-change){
			width:33.33%;
		}
	}
	.col-75{

		&:not(._not-change){
			width:66.66%;
		}
	}
	.col-33,
	.col-66{

		&:not(._not-change){
			width:50%;
		}
	}
	.col-temp{

		&._media-tablet_solo{
			width:100%;
		}
		&._media-tablet_half{
			width:50%;
		}
	}
	/* .header */
	.header{
		position:relative;
		z-index:10;
	}
	/* .nav */
	.nav{
		padding:0 !important;
		position:absolute;
		top:100%;
		right:0;
		z-index:90;
		width:100%;
		background:$back-dark;
		// max-height:calc(100vh - 105px);
		max-height:50vh;
		overflow-y:auto;
		-webkit-overflow-scrolling:touch;
		overscroll-behavior:contain;
		display:none;

		&._active{
			display:block;
		}
	}
	.nav-list{
		border-bottom:1px solid rgba($dirty, 0.5);
	}
	.nav-list__item{
		border-top:1px solid rgba($dirty, 0.5);
	}
	.nav-list__link{
		font-size:14px;
		line-height:16px;
		padding:12px 10px;
	}
	.nav-list__dropdown{

	}
	.nav-list__dropdown-list{

		&._active{
			position:static;
		}
	}
	.nav-list__dropdown-item{
		border-top:1px solid rgba($dirty, 0.5);
	}
	.nav-list__dropdown-link{
		padding:10px 10px 10px 20px;
		font-size:14px;
		line-height:16px;
		font-weight:700;
	}
	.nav-list__dropdown-button{

		&._active{

			.nav-list__dropdown-buttonicon{
				transform:rotateZ(-180deg);
			}
		}
	}
	.nav-list__dropdown-buttonicon{
		color:$dirty;
		top:12px;
		right:10px;
	}
	/* .slick */
	.slick-dots{

		button{

		}
	}
	/* .promo */
	.promo{
		height:360px;
	}
	/* .venobox */
	.venobox-cover__icon{
		// right:auto;
		// bottom:auto;
		// width:80px;
		// height:80px;
		// background:$brand;
		// padding:8px;
	}
	/* .map-frame */
	.map-frame{
		height:300px;
	}
}/* (max-width:1024px) */
@media screen and (max-width:768px){
	/* .line- */
	.line-section{
		margin-top:50px;
	}
	.line-content{
		margin-top:36px;
	}
	/* .cols */
	.col-20,
	.col-25,
	.col-75{

		&:not(._not-change){
			width:50%;
		}
	}
	.col-temp{

		&._media-tabletmedium_solo{
			width:100%;
		}
	}
	/* .promo */
	.promo{
		height:300px;
	}
}/* (max-width:768px) */
@media screen and (max-width:600px){
	/* .line- */
	.line-section{
		margin-top:40px;
	}
	/* .section- */
	.section-inside{
		padding-top:30px;
		padding-bottom:30px;
	}
	/* .wrapper */
	.wrapper{
		width:94%;
	}
	/* .cols */
	.col-25,
	.col-33,
	.col-50,
	.col-66,
	.col-75{

		&:not(._not-change){
			width:100%;
		}
	}
	.col-temp{

		&._media-tabletsmall_half,
		&._media-tabletsmall_halfonly{
			width:50%;
		}
		&._media-tabletsmall_solo{
			width:100%;
		}
		&._media-tablet_half{
			width:100%;
		}
	}
	/* .header */
	.header__wrapper{
		display:block;
	}
	.header__content{
		padding:0;
	}
	.header__bar-mobile{
		justify-content:space-between !important;
		margin:-5px !important;
	}
	.header__bar-col{
		padding:5px !important;
	}
	/* .logo */
	.logo{
		margin-left:auto;
		margin-right:auto;
	}
	/* .promo */
	.promo{
		height:260px;
	}
	/* .tubes */
	.tube__frame{
		padding-top:26px;
		padding-bottom:26px;
	}
}/* (max-width:600px) */
@media screen and (min-width:481px){
	/* .media- */
	.media-mobile_show{
		display:none;
	}
}
@media screen and (max-width:480px){
	/* .media- */
	.media-mobile_hide{
		display:none;
	}
	/* .wrapper */
	.wrapper{
		width:90%;
	}
	/* .cols */
	.cols{

		// &:not(._sm):not(._clean){
		// 	margin:-5px;

		// 	> .col-temp{
		// 		padding:5px;
		// 	}
		// }
		&._indent-large,
		&._indent-base{
			margin:-10px;

			> .col-temp{
				padding:10px;
			}
		}
	}
	.col-20{

		&:not(._not-change){
			width:100%;
		}
	}
	.col-temp{

		&._media-tabletsmall_half{
			width:100%;
		}
		&._media-mobile_solo{
			width:100%;
		}
	}
	/* .promo */
	.promo{
		height:200px;
	}
	/* .map-frame */
	.map-frame{
		height:200px;
	}
}/* (max-width:480px) */






