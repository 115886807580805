$font-base:Arial, Helvetica, sans-serif;
/* General Reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,legend,fieldset,input,textarea,p,blockquote,th,td{margin:0;padding:0;}fieldset,img{border:0;}img,object,embed{vertical-align:bottom;}address,caption,cite,code,dfn,th,var{font-style:inherit;font-weight:inherit;}ol,ul{list-style:none;}caption,th{text-align:left;}q:before,q:after{content:'';}a *{cursor:pointer;}input,textarea,select{vertical-align:middle;}
aside, nav, footer, header, section, article, figure, figcaption, main, legend{
	display:block;
	margin:0;
}
fieldset{
	display:block;
	min-width:100%;
}
legend{
	width:100%;
}
mark{
	background:none;
	color:inherit;
}
abbr{
	cursor:help;
	text-decoration:none;
	border-bottom:none;
}
label[for]{
	-webkit-tap-highlight-color:transparent;
}
.cursor-pointer{
	cursor:pointer;
}
/* .visually-hidden */
.visually-hidden{
	position:absolute;
	width:1px;
	height:1px;
	margin:-1px;
	border:0;
	padding:0;
	clip:rect(0 0 0 0);
	overflow:hidden;
}
*,
*:before,
*:after{
	box-sizing:border-box;
}
//colors
$brand:#8cc63f;
$brand_b:#FFA500;//orange

// $dark:#000;
// $darken:#ddd;
// $dark-light:#b4b4b4;

$back-base:#fff;
$back-dark:#333;
$back-dark_dirty:#6d6e6f;
$back-dark_black:#414042;
// $back-white:#000;

$dirty:#ccc;

$text-base:#444;
$text-brand:#8cc63f;
$text-dark:#222;
$text-dirty:#ccc;
$text-white:#fff;

$red:#c00;

$overlay_temp:#000;
$overlay:rgba($overlay_temp, 0.7);

//indents
// $section-indent:30px;
// $section:30px;
// $indent-mega
$indent-section:80px;
$indent-content:40px;
// $indent-xxl
$indent-xl:30px;
$indent-l:26px;
// $indent-m
$indent-md:20px;
$indent-s:16px;
// $indent-sm
// $indent-xs
$indent-xm:8px;

/* .line- */
$indents:(
	section:($indent-section),
	content:($indent-content),
	xl:($indent-xl),
	l:($indent-l),
	md:($indent-md),
	s:($indent-s),
	xm:($indent-xm)
);
.line-temp{
	display:block;

	&:first-child{
		margin-top:0;
	}
	&:empty{
		display:none;
	}
}
@each $item, $property in $indents{
	.line-#{$item}{
		margin-top:#{$property};
	}
}

//@mixins / %vars

//@mixin transition-base
$transition-duration: 250ms;
$transition-style: ease;
@mixin transition-base($property...){
	@if length($property) > 0 {
		$listprop: null;
		@each $propert in $property {
			$listprop:append($listprop, $propert $transition-duration $transition-style 0s, comma);
		}
		transition:$listprop;
	}
	@else {
		transition:all $transition-duration $transition-style 0s;
	}
	//@include transition-base(opacity, visibility);
	//will-change:opacity, visibility;//добавлять руками
	//@include transition-base();
	//@include transition-base;
}

// @mixin radius(){
// 	border-radius:0;
// }
// @mixin scale-base(){
// 	transform:scale(1.1);
// }
%clearfix{
	&:after{
		content:'';
		display:table;
		clear:both;
	}
}
.clearfix{
	@extend %clearfix;
}
//line-height 'px' to 'em'
@function em($el_fs, $el_lh){
	//$el_fs - font-size 'px'
	//$el_lh - line-height 'px'
	@return #{$el_lh/$el_fs}em;
}
/* .paragraph- */
%paragraph-base,
.paragraph-base{
	font-size:14px;
	line-height:em(14, 22);
}
.paragraph-medium{
	font-size:16px;
	line-height:em(16, 24);
}
.paragraph-small{
	font-size:11px;
	line-height:14px;
}
/* General settings */
html{
	height:100%;
	background:$back-base;
}
body{
	color:$text-base;
	font-family:$font-base;
	min-width:320px;
	height:100%;
	background:$back-base;
	position:relative;
	-webkit-text-size-adjust:none;
	text-size-adjust:none;
}
/* icon-svg */
.icon-svg{
	// @include transition-base(fill);
	display:inline-block;
	vertical-align:top;
	width:30px;
	height:30px;
	fill:currentColor;

	&._size-md{
		width:24px;
		height:24px;
	}
	&._size-m{
		width:20px;
		height:20px;
	}
	&._size-sm{
		width:16px;
		height:16px;
	}
}
/* .link */
a{
	color:inherit;
	text-decoration:underline;
	outline:none;

	&:hover{
		text-decoration:none;
	}
	&:focus{
		outline:none;
		outline-offset:0;
		text-decoration:none;

		&:not([class]){
			text-decoration:none;
			border-bottom:1px dotted currentColor;
		}
	}
	&:visited{}
	&:active{

	}
}
.link-dirty{
	text-decoration:none;

	&:hover{
		text-decoration:none;
	}
}
/* button */
.button-reset,
%button{
	display:inline-block;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	border:none;
	white-space:normal;
	user-select:none;
	background-image:none;
	background:none;
	font-family:$font-base;
	line-height:inherit;
	font-size:inherit;
	padding:0;
	margin:0;
	min-width:0;
	height:auto;
	width:auto;
	border-radius:0;
	// @include transition-base;
	-webkit-appearance:none;
	cursor:pointer;
	outline:none;
	border-spacing:0;
	text-indent:0;
	color:inherit;
	overflow:visible;
	text-transform:none;
	-webkit-tap-highlight-color:transparent;

	&:focus,
	&:hover{
		outline:none;
		cursor:pointer;
		text-decoration:none;
	}
	&::-moz-focus-inner{
		border: 0;
		padding: 0;
	}
	&[disabled]{
		cursor:default;
	}
	&._block{
		display:block;
		width:100%;
	}
}
/* .buttonframe */
.buttonframe{
	display:inline-block;
	vertical-align:top;
}
.buttonframe__side-left{
	margin-right:10px;
}
.buttonframe__crop{
	display:block;
	width:16px;
	height:16px;
}
.buttonframe__crop-icon{

	&._size-m{
		transform:translate(-2px,-2px);
	}
}
/* .button-base */
.button-base{
	@extend %button;
	font-size:13px;
	line-height:16px;
	padding:10px 20px;
	text-transform:uppercase;
	font-weight:600;
	border:2px solid $brand;

	&:focus,
	&:hover{
		border-color:$back-dark;
	}
	&:active{

	}
	&[disabled]{

	}
	&._fill{
		background:$brand;
		color:#fff;

		&:focus,
		&:hover{
			color:$back-dark;
		}
		&:active{
			border-color:$brand;
			color:#fff;
		}
	}
	&._empty{
		color:$brand;

		&:focus,
		&:hover{
			color:#fff;
			border-color:#fff;
		}
		&:active{
			color:$brand;
			border-color:$brand;
		}
	}
}
/* .button-text */
.button-text{
	font-size:14px;
	line-height:16px;
	color:$text-brand;
	font-weight:700;
	text-transform:uppercase;

	&:focus,
	&:hover{
		color:$text-dirty;
	}
	&:active{
		border-color:$brand;
		color:#fff;
	}
}
/* .title */
.title-reset{
	font-weight:inherit;
	font-size:inherit;
	line-height:inherit;

	&._block{
		display:block;
		// width:100%;
	}
}
h1,
h2,
h3,
h4,
h5,
h6,
legend{
	font-weight:inherit;
	// font-weight:400;
}
legend{
	display:block;
}
%title-temp{
	text-transform:uppercase;
	font-weight:700;
}
.title-primary{
	font-size:34px;
	line-height:1.2em;
	@extend %title-temp;
	color:$text-brand;
	letter-spacing:1px;
}
.title-mega{
	font-size:30px;
	line-height:1.2em;
	@extend %title-temp;
	color:$text-brand;
}
.title-section{
	font-size:30px;
	line-height:1.2em;
	text-transform:uppercase;
	font-weight:400;
	letter-spacing:1px;
}
.title-regular{

}
.title-leading{

}
.title-heading{

}
.title-xl{

}
.title-l{

}
.title-m{

}
.title-md{
	font-size:18px;
	line-height:22px;
	@extend %title-temp;
	letter-spacing:1px;
	color:$text-brand;
}
.title-s{
	font-size:15px;
	line-height:22px;
	letter-spacing:1px;
	@extend %title-temp;
}
.title-sm{
	font-size:14px;
	line-height:22px;
	letter-spacing:1px;
	@extend %title-temp;
	color:$text-brand;
}
.title-xs{

}
.title-xm{

}
.title-xxs{

}
/* .text-container */
.text-container{
	@extend %paragraph-base;
	$indent-top:10px;

	> *{
		margin-top:$indent-top;

		&:first-child{
			margin-top:0;
		}
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6{
		font-weight:700;
		line-height:1.2em;
		color:$text-brand;
	}
	h1{
		font-size:34px;
	}
	h2{
		font-size:30px;
	}
	h3{
		font-size:26px
	}
	h4{
		font-size:22px;
	}
	h5{
		font-size:18px;
	}
	h6{
		font-size:14px;
	}
	a:not([class]){
		text-decoration:underline;

		&:focus,
		&:hover{
			text-decoration:none;
		}
	}
	p{

		img{
			max-width:100%;
			height:auto;
		}
	}
	strong{
		font-weight:700;
	}
	ul:not([class]){
		padding-left:20px;
		list-style-type:disc;
	}
	ol:not([class]){
		padding-left:20px;
		list-style-type:decimal;
	}
	ul:not([class]),
	ol:not([class]){

		li{
			margin-top:$indent-top;
		}
	}
	em{
		font-style:italic;
		//font-family:$font-base__italic;
	}
}
// /* .font- */
// $font-weight:(
// 	regular:(400),
// 	semibold:(600),
// 	bold:(700)
// );
// @each $item, $property in $font-weight{
// 	.font-#{$item}{
// 		font-weight:#{$property};
// 	}
// }
/* .color_ */
$colors:(
	brand:($text-brand),
	brand-b:($brand_b),
	dark:($text-dark)
);
@each $item, $property in $colors{
	.color_#{$item}{
		color:#{$property};
	}
}
/* .text- */
.text-left{
	text-align:left;
}
.text-center{
	text-align:center;
}
.text-right{
	text-align:right;
}
.text-ls{
	letter-spacing:1px;
}
.text-uppercase{
	text-transform:uppercase;
}
/* .inline */
.inline{
	display:inline;
}
/* .img- */
.img-content{
	max-width:100%;
	width:auto;
	height:auto;
}
.img-block{
	display:block;
	width:100%;
	height:auto;
}
.img-radius{
	border-radius:50%;
}
/* @media */
@media screen and (min-width:1025px){
	.link-dirty{

		&:hover{
			color:$text-dirty;
		}
	}
}
@media screen and (max-width:1024px){
	
}/* (max-width:1024px) */
@media screen and (max-width:768px){
	/* .title- */
	.title-primary{
		font-size:30px;
	}
	.title-mega,
	.title-section{
		font-size:26px;
	}
	/* .text-container */
	.text-container{

		h1{
			font-size:30px;
		}
		h2{
			font-size:26px;
		}
		h3{
			font-size:24px
		}
	}
}/* (max-width:768px) */
@media screen and (max-width:600px){
	/* .button-reset */
	.button-reset{
	
		&._media-tabletsmall_block{
			display:block;
			width:100%;
		}
	}
	/* .title- */
	.title-primary{
		font-size:26px;
	}
	.title-mega,
	.title-section{
		font-size:24px;
	}
	/* .text-container */
	.text-container{

		h1{
			font-size:26px;
		}
		h2{
			font-size:24px;
		}
		h3{
			font-size:22px
		}
		h4{
			font-size:20px;
		}
	}
}/* (max-width:600px) */
@media screen and (max-width:480px){
	
}/* (max-width:480px) */